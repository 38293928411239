import React,{ useState,useEffect } from 'react';
import { Layout ,Message} from '@arco-design/web-react';
import {  IconRight, IconLeft } from '@arco-design/web-react/icon';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from './components/Breadcrumb';
import { GET } from "@/utils/apis"
import { IUserInfo } from "@/types/user"
import Menus from './components/Menus';
import LayoutHeader from "./components/LayoutHeader"
import useGlobalStore from "@/store/global";
import { useLocation } from 'react-router-dom';
import {
  IUserReportItem,
  ITreeType,
} from '@/types/dashbroad';
import './index.scss';
const Header = Layout.Header;
const Content = Layout.Content;
const Sider = Layout.Sider;
const Index = (): React.ReactElement => {
  const [collapsed, setCollapsed] = useState(true);
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [projectTree,setProjectTree]=useState<ITreeType[]>()
  const [activeNodes,setActiveNodes]=useState<string[]>()
  const [projectLists,setProjectLists]=useState<any[]>([])
  const location = useLocation()
  const {setProjectId,setAlgorithmId,setAlgorithmMap,setProjectAlgTree} = useGlobalStore()
  const initData = async () => {
      // setLoadingProject(true)
      try {
        const { data }: any = await GET('/project/project/userReportOption');
        const projectList = (data?.projectList || []) as IUserReportItem[];
        if (projectList.length == 0) {
          Message.error("用户报告请求数据为空！");
          return
        }
        setProjectLists(projectList)
        
      }catch(e){

      }
  }
   const fetchUserInfo = async () => {
      try {
        const { data } = await GET('/system/user/getInfo');
        setUserInfo(data)
      } catch (e) {
        console.log(e)
      }
    }
    useEffect(() => {
      const tree: ITreeType[] = []
        const algorithmMapTmp = new Map()
        for (const project of projectLists) {
          const { projectId, projectName, algorithmList, reportUrl, adsCostReport,biReport } = project
          const treeNode: ITreeType = {
            disabled: !biReport&&location.pathname.includes('/proxyBoard'),
            value: String(projectId),
            label: projectName,
            children: [],
            reportUrl,
            adsCostReport
          }
          for (const algorithm of algorithmList) {
            const { algorithmId, algorithmName, startDate, endDate } = algorithm;
            treeNode.children?.push({
              value: String(algorithmId),
              label: algorithmName,
              startDate,
              endDate
            })
            algorithmMapTmp.set(String(algorithmId), algorithmName)
          }
          tree.push(treeNode)
        }
        setAlgorithmMap(algorithmMapTmp)
        setProjectTree(tree)
        setProjectAlgTree(tree)
    
        let node: string[] = [];
        let current: undefined | ITreeType = tree?.[0];
        while (current) {
          node.push(current.value);
          current = current.children?.[0];
        }
        const cacheProject=localStorage.getItem('projectInfor')
        if(cacheProject){
          node=JSON.parse(cacheProject)
        }
        setActiveNodes(node)
        setProjectId(node[0])
        setAlgorithmId(node[1])
        const keys = Object.keys(localStorage)
        keys.forEach((key) => {
          const bool1 = !key.startsWith(`${node![0]}_${node![1]}`) // bool1
          const bool2= key.endsWith('campaignSelect')||key.endsWith('nameSelect')||key.endsWith('adsCostSourceSelect')||key.endsWith('sourceSelect')||key.endsWith('quadrant')||key.endsWith('sessionLevel')
          if (bool1&&bool2) {
            localStorage.removeItem(key); // 删除匹配的键
          }
        })
    },[location.pathname,projectLists])
    useEffect(() => {
      fetchUserInfo()
      initData()
    }, [])
  const handleChangeNode=(node:string[])=>{
    setActiveNodes(node)
    setProjectId(node[0])
    setAlgorithmId(node[1])
  }
  const handleCollapsed = () => {
    setCollapsed(!collapsed)
  }
    return (
      <Layout className='layout-collapse '>
        <Sider  
         collapsed={collapsed}
         className='relative'
        >
        <div className='absolute top-[10%] right-[-10px] z-10 cursor-pointer w-[20px] h-[20px] rounded-full bg-[#98b0e9] flex-center' onClick={handleCollapsed}>{collapsed ? <IconRight style={{ color: '#ffffff' }}/> : <IconLeft style={{ color: '#ffffff' }} />}</div>
        <Menus iscollapsed={collapsed}  userInfo={userInfo}/>
        </Sider>
        <Layout className='bg-slate-50'>
          <Header className='pl-[20px] sticky top-0 left-0 z-10 w-full flex-center bg-white  border-gray-300 shadow-[0_2px_5px_0_rgba(0,0,0,0.08)]'>
            <LayoutHeader projectTree={projectTree} activeNodes={activeNodes} setActiveNodes={handleChangeNode} userInfo={userInfo}/>
            </Header>
          <Layout style={{ padding: '0 10px' }} className='z-0'>
            <Breadcrumbs></Breadcrumbs>
            <div className='bg-[#F9FAFD] w-full h-full'>
              <Outlet context={[userInfo, setUserInfo]}></Outlet>
            </div>
          </Layout>
        </Layout>
      </Layout>
    )
}

export default Index;